// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-checklist-js": () => import("./../../../src/pages/checklist.js" /* webpackChunkName: "component---src-pages-checklist-js" */),
  "component---src-pages-dividend-champions-js": () => import("./../../../src/pages/dividend-champions.js" /* webpackChunkName: "component---src-pages-dividend-champions-js" */),
  "component---src-pages-esg-stocks-summary-js": () => import("./../../../src/pages/esg-stocks-summary.js" /* webpackChunkName: "component---src-pages-esg-stocks-summary-js" */),
  "component---src-pages-google-trend-stocks-js": () => import("./../../../src/pages/google-trend-stocks.js" /* webpackChunkName: "component---src-pages-google-trend-stocks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-insiders-js": () => import("./../../../src/pages/industry-insiders.js" /* webpackChunkName: "component---src-pages-industry-insiders-js" */),
  "component---src-pages-industry-market-js": () => import("./../../../src/pages/industry-market.js" /* webpackChunkName: "component---src-pages-industry-market-js" */),
  "component---src-pages-insiders-trade-list-js": () => import("./../../../src/pages/insiders-trade-list.js" /* webpackChunkName: "component---src-pages-insiders-trade-list-js" */),
  "component---src-pages-investment-gurus-js": () => import("./../../../src/pages/investment-gurus.js" /* webpackChunkName: "component---src-pages-investment-gurus-js" */),
  "component---src-pages-market-correlation-matrix-js": () => import("./../../../src/pages/market-correlation-matrix.js" /* webpackChunkName: "component---src-pages-market-correlation-matrix-js" */),
  "component---src-pages-options-pcr-js": () => import("./../../../src/pages/options-pcr.js" /* webpackChunkName: "component---src-pages-options-pcr-js" */),
  "component---src-pages-options-valuation-js": () => import("./../../../src/pages/options-valuation.js" /* webpackChunkName: "component---src-pages-options-valuation-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-short-stocks-summary-js": () => import("./../../../src/pages/short-stocks-summary.js" /* webpackChunkName: "component---src-pages-short-stocks-summary-js" */),
  "component---src-pages-stock-benford-law-js": () => import("./../../../src/pages/stock-benford-law.js" /* webpackChunkName: "component---src-pages-stock-benford-law-js" */),
  "component---src-pages-stock-peer-comparison-js": () => import("./../../../src/pages/stock-peer-comparison.js" /* webpackChunkName: "component---src-pages-stock-peer-comparison-js" */),
  "component---src-pages-stock-price-simulation-js": () => import("./../../../src/pages/stock-price-simulation.js" /* webpackChunkName: "component---src-pages-stock-price-simulation-js" */)
}

